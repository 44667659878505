import React from 'react';
import styles from './Terms.module.scss';

const Terms = ({ checked, onChange }) => {
    return (
        <div>
            <b>By clicking accept, you agree to the following conditions:</b>
            <ul>
                <li>Video recording, audio recording and/or capturing screenshots by visitors is strictly prohibited.</li>
                <li>Calls may be happening in a shared space - please be mindful when having sensitive or private conversations.</li>
            </ul>
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

export default Terms;
