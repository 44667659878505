import logo from './logo.png';
import './App.css';

import Terms from './components/shared/terms/Terms.js';

function App() {
  const getParam = (variable) => {
    var query = window.location.search.substring(1);
    if(!query) {
      query = window.location.hash.replace('#/?', '');
    }

    console.log(query);

    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1]; }
    }

    return (false);
  }

  const doRedirect = () => {
    let uuid = getParam('u');
    let name = getParam('n');

    if(!uuid || !name) {
      alert('Something went wrong, please close this page and click the email link again.');
    } else {
      console.log(process.env.REACT_APP_REDIRECT_URL);

      let url = encodeURI(`https://${process.env.REACT_APP_REDIRECT_URL}#/?conference=${uuid}&name=${name}&pin=&role=guest&muteMicrophone=${process.env.REACT_APP_MIC_MUTE}&muteCamera=false&callType=video&extension=`);
      console.log('Redirection to', url);
      window.location.href = url;
    }
  }

  return (
    <div className='pageContainer'>
      <div className='topBar'>
        <div className='topBarLogo'><img src={logo} alt='NSW Health Logo' /></div>
        <div className='topBarRight'></div>
      </div>
      <div className='terms'>
        <div className='termsTitle'>Welcome to Visitation</div>
        <div className='termsContent'><Terms /></div>
      </div>
      <div className='acceptTermsBar'>
        {/* <div className='acceptTermsCheckbox'>
          <input type='checkbox' name='acceptTerms' />
          <label for='acceptTerms'>I have read and accept the above statement and agree to continue</label>
        </div> */}
        <div className='acceptTermsButton'>
          <button onClick={doRedirect}>Accept</button>
        </div>
      </div>
    </div>
  );
}

export default App;
